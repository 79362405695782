<template>
    <div
        class="border border-cool-gray-200 rounded p-5 mb-5 text-left bg-white"
    >
        <router-link
            v-if="routeName"
            :to="{ name: routeName }"
            class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 float-right"
            data-cy="snippet-edit-a"
        >
            {{ $t("edit") }}
        </router-link>
        <slot />
    </div>
</template>

<script>
export default {
    name: "BoxSnippet",
    props: {
        routeName: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style></style>
