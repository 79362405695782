<template>
    <div class="h-28 ">
        <div v-show="backButtonAction" class="flex content-start ">
            <div>
                <button class="" @click="backButtonAction">
                    <IconLongArrow
                        v-if="showIcon"
                        dir="left"
                        class-name="h-4 mr-1 -mt-px lg:h-5 inline-block"
                    />
                    <slot name="back" />
                </button>
            </div>
        </div>
        <div class="main-title ">
            <slot name="header" />
        </div>
        <div class="sub-header ">
            <slot name="sub-header" />
        </div>
    </div>
</template>

<script>
import { IconLongArrow } from "../Icons";
export default {
    name: "CheckoutHeader",
    components: {
        IconLongArrow,
    },
    props: {
        showIcon: {
            type: Boolean,
            required: false,
            default: true,
        },
        backButtonTitle: {
            type: String,
            required: false,
            default: "Voltar para o início",
        },
        backButtonAction: {
            type: [Function, Boolean],
            required: true,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.step-back-button {
    @apply text-xs text-gray-500 rounded cursor-pointer text-left w-48;
}
.step-back-button:hover {
    @apply text-gray-900;
    &.disabled {
        @apply bg-cool-gray-50 #{!important};
    }
}

.wrapper-buttons {
    @apply inline-block float-left w-full mb-2;
}
.main-title,
.sub-header {
    @apply text-sm text-center w-full;
}
.sub-header {
    @apply mt-1;
}

@screen sm {
    .step-back-button {
        @apply text-sm;
    }
    .main-title {
        @apply text-base;
    }
}
@screen md {
    .main-title,
    .sub-header {
        @apply text-lg;
    }
}
@screen lg {
    .main-title {
        @apply text-2xl;
    }
    .sub-header {
        @apply text-xl;
    }
    .step-back-button {
        @apply text-base;
    }
}
</style>
