var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-28" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.backButtonAction,
            expression: "backButtonAction",
          },
        ],
        staticClass: "flex content-start",
      },
      [
        _c("div", [
          _c(
            "button",
            { on: { click: _vm.backButtonAction } },
            [
              _vm.showIcon
                ? _c("IconLongArrow", {
                    attrs: {
                      dir: "left",
                      "class-name": "h-4 mr-1 -mt-px lg:h-5 inline-block",
                    },
                  })
                : _vm._e(),
              _vm._t("back"),
            ],
            2
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "main-title" }, [_vm._t("header")], 2),
    _c("div", { staticClass: "sub-header" }, [_vm._t("sub-header")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }