var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border border-cool-gray-200 rounded p-5 mb-5 text-left bg-white",
    },
    [
      _vm.routeName
        ? _c(
            "router-link",
            {
              staticClass:
                "underline text-blue-600 hover:text-blue-800 visited:text-purple-600 float-right",
              attrs: {
                to: { name: _vm.routeName },
                "data-cy": "snippet-edit-a",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }